var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"billingInformations-wrapper"},[(
      _vm.billingInformations.billing_entity_type ===
      _vm.BILLING_ENTITY_TYPE_INDIVIDUAL
    )?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.LASTNAME")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.billingInformations.billing_lastname)+" ")])]):_vm._e(),(
      _vm.billingInformations.billing_entity_type ===
      _vm.BILLING_ENTITY_TYPE_INDIVIDUAL
    )?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.FIRSTNAME")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.billingInformations.billing_firstname)+" ")])]):_vm._e(),(
      _vm.billingInformations.billing_entity_type === _vm.BILLING_ENTITY_TYPE_COMPANY
    )?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.COMPANY_NAME")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.billingInformations.billing_company_name)+" ")])]):_vm._e(),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.COUNTRY")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.billingInformations.billing_country)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.STATE")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.billingInformations.billing_state)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.CITY")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.billingInformations.billing_city)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.ZIPCODE")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.billingInformations.billing_zipcode)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.ADDRESS")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.billingInformations.billing_address)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.EMAIL")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.billingInformations.billing_email)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }